import { BASE_URL } from 'app/constants/api'
import { ToastError } from 'app/utils/Toast'
const http = async (url, options) => {
    console.log(`API request URL : ${BASE_URL}/${url}`)
    console.log(`API request options :`, options)
    try {
        let response

        if (!options) response = await fetch(`${BASE_URL}/${url}`)

        if (options) response = await fetch(`${BASE_URL}/${url}`, options)

        response = await response.json()

        // if (!response.success) alert("some thing went wrong");
        // if (!response.success) console.log("some thing went wrong", response.error);

        return response
    } catch (error) {
        ToastError(`network request failed`)
        console.log(`network request failed ${error}`)
    }
}

export default http
