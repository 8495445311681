import React from 'react'
import { authRoles } from '../../utils/services/auth/authRoles'

const vendorRoutes = [

    {
        path: '/vendor/store/add-new',
        component: React.lazy(() => import('./store/add/storeInfoCollection/addnewstore')),
        auth: authRoles.sa,
    },
    {
        path: '/vendor/store/catalog/profile',
        component: React.lazy(() => import('./store/catalog/profile')),
        auth: authRoles.sa,
    },
    {
        path: '/vendor/store/catalog/subcategories',
        component: React.lazy(() => import('../vendor/store/catalog/subcategories')),
        auth: authRoles.sa,
    },
    {
        path: '/vendor/store/catalog/:subCategoryId/products',
        component: React.lazy(() => import('../vendor/store/catalog/products')),
        auth: authRoles.sa,
    },
    {
        path: '/vendor/store/catalog/customers',
        component: React.lazy(() => import('../vendor/store/catalog/customers')),
        auth: authRoles.sa,
    },
    {
        path: '/vendor/store/support/conversations',
        component: React.lazy(() => import('../vendor/store/support/conversations')),
        auth: authRoles.sa,
    },
    {
        path: '/vendor/growth/sales/orders',
        component: React.lazy(() => import('../vendor/growth/sales/Orders')),
        auth: authRoles.sa,
    },
    {
        path: '/vendor/growth/sales/invoices',
        component: React.lazy(() => import('../vendor/growth/sales/Invoices')),
        auth: authRoles.sa,
    },

    {
        path: '/vendor/store/support/chat',
        component: React.lazy(() => import('../vendor/store/support/chat')),
        auth: authRoles.sa,
    },
    {
        path: '/vendor/store/support/refunds',
        component: React.lazy(() => import('../vendor/store/support/refunds')),
        auth: authRoles.sa,
    },
    {
        path: '/vendor/settings/user',
        component: React.lazy(() => import('../vendor/settings/user/userSettings.jsx')),
        auth: authRoles.sa,
    }
]

export default vendorRoutes
