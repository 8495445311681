import React from "react";

export const StarRating = (props) => {

    const [state, setState] = React.useState({
        selectedIcon: "★",
        deselectedIcon: "☆"
    });

    const { deselectedIcon, selectedIcon } = state;

    return (
        <div>
            <div className="rating" style={{ fontSize: '2em', color: "#3FC2BD" }}>

                {Array.from([1,2,3,4,5]).map((star,i) => {
                    return (
                        <span
                            key={i}
                            style={{ cursor: 'pointer' }}
                        >
                            {props.stars < star ?
                                deselectedIcon
                                :
                                selectedIcon
                            }
                        </span>
                    );
                })}

            </div>
        </div>
    );
}

export default StarRating