import * as React from 'react';
import { styled, alpha } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
// import ArchiveIcon from '@material-ui/core/Icon/Archive';
// import FileCopyIcon from '@material-ui/core/Icon/FileCopy';
// import MoreHorizIcon from '@material-ui/core/Icon/MoreHoriz';
// import KeyboardArrowDownIcon from '@material-ui/core/Icon/KeyboardArrowDown';
import Divider from '@material-ui/core/Divider';
import { IconButton } from '@material-ui/core';
import swal from 'sweetalert';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        // color:theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        color: 'black',
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function CustomizedMenus({image: productImage}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [status, setStatus] = React.useState("PENDING");
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = async(status) => {
        setAnchorEl(null);
        const willChange = await swal({
            title: "Are you sure?",
            text: `Are you sure that you want to change status For order# IIBSO2234 ?`,
            icon: productImage,
            closeOnClickOutside:false,
            dangerMode: true,
        });
        
        if (willChange) {
            setStatus(status)
            swal("Status Changed!", `Status For order# IIBSO2234 has been changed to ${status}`, "success");
        }
    };

    return (
        <div>
            {/* <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<Icon>keyboard_arrow_down</Icon>}
      >
        Options
      </Button> */}
            <IconButton
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Icon color="action">info</Icon>
            </IconButton>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={()=>handleClose("PENDING")} selected={status==="PENDING"} >
                    {/* <EditIcon /> */}
                    PENDING
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={()=>handleClose("SHIPPED")}  selected={status==="SHIPPED"}>
                    {/* <FileCopyIcon /> */}
                    SHIPPED
                </MenuItem>
            </StyledMenu>
        </div>
    );
}