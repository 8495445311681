import React, { useState } from 'react'
import {
    Card,
    // Checkbox,
    // FormControlLabel,
    Grid,
    // Button,
    Typography,
} from '@material-ui/core'
// import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import RegistrationHS  from './RegistrationHS'


const useStyles = makeStyles(({ palette, ...theme }) => ({
    cardHolder: {
        background: '#1A2038',
    },
    card: {
        maxWidth: 800,
        borderRadius: 12,
        margin: '1rem',
    },
}))

const JwtRegister = () => {
    const classes = useStyles()
    const route = useLocation();

    return (
        <div
            className={clsx(
                'flex justify-center items-center  min-h-full-screen',
                classes.cardHolder
            )}
        >
            <Card className={classes.card}>
                <Grid container>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                        <div className="p-8 flex justify-center bg-light-gray items-center">
                            {/* <img
                                className="w-full"
                                src="/assets/images/illustrations/posting_photo.svg"
                                alt=""
                            /> */}
                            <img
                                className="w-full"
                                src="/assets/icon/app.png"
                                alt="app"
                            />
                        </div>
                    </Grid>
                    <Grid item lg={7} md={7} sm={7} xs={12}>
                        <div className="p-8 h-full">
                            <Typography>Welcome to IIBSO we are happy to have you onboard! </Typography>
                            <Typography>Please follow the steps to kick start your journey with IIBSO </Typography>
                            <RegistrationHS vendorId={route.state?.vendorId} vendorEmail={route.state?.email}/>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </div>
    )
}

export default JwtRegister


// {/* <ValidatorForm onSubmit={handleFormSubmit}>
// <TextValidator
//     className="mb-6 w-full"
//     variant="outlined"
//     size="small"
//     label="Username"
//     onChange={handleChange}
//     type="text"
//     name="userName"
//     value={userName || ''}
//     validators={['required']}
//     errorMessages={['this field is required']}
// />


// <TextValidator
//     className="mb-6 w-full"
//     variant="outlined"
//     size="small"
//     label="Phone Number"
//     onChange={handleChange}
//     type="'tel'"
//     name="phoneNumber"
//     value={phoneNumber || ''}
//     validators={['required']}
//     errorMessages={['this field is required']}
// />
// {/* <TextValidator
//     className="mb-6 w-full"
//     variant="outlined"
//     size="small"
//     label="Email"
//     onChange={handleChange}
//     type="email"
//     name="email"
//     value={email || ''}
//     validators={['required', 'isEmail']}
//     errorMessages={[
//         'this field is required',
//         'email is not valid',
//     ]}
// /> */}
// {/* <TextValidator
//     className="mb-4 w-full"
//     label="Password"
//     variant="outlined"
//     size="small"
//     onChange={handleChange}
//     name="password"
//     type="password"
//     value={password || ''}
//     validators={['required']}
//     errorMessages={['this field is required']}
// /> */}
// <FormControlLabel
//     className="mb-4"
//     name="agreement"
//     onChange={(e) =>
//         handleChange({
//             target: {
//                 name: 'agreement',
//                 value: e.target.checked,
//             },
//         })
//     }
//     control={
//         <Checkbox
//             size="small"
//             checked={agreement || false}
//         />
//     }
//     label="I have read and agree to the terms of service."
// />
// <div className="flex items-center">
//     <Button
//         className="capitalize"
//         variant="contained"
//         color="primary"
//         type="submit"
//     >
//         Sign up
//     </Button>
//     <span className="mx-2 ml-5">or</span>
//     <Link to="/session/signin">
//         <Button className="capitalize">
//             Sign in
//         </Button>
//     </Link>
// </div>
// </ValidatorForm> */}