import { ACTIONS } from 'app/constants/actions'

const initialState = {
    categories: [],
    selectedCategoryDetails: {},
}

export default function categoryReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.STORE.CATEGORY.GET_CATEGORIES: {
            return {
                ...state,
                categories: action.payload,
            }
        }
        case ACTIONS.STORE.CATEGORY.GET_CATEGORY_DETAILS: {
            return {
                ...state,
                selectedCategoryDetails: [...action.payload],
            }
        }
        case ACTIONS.STORE.CATEGORY.ADD_NEW_CATEGORY: {
            return {
                ...state,
            }
        }
        case ACTIONS.STORE.CATEGORY.UPDATE_CATEGORY: {
            return {
                ...state,
            }
        }
        case ACTIONS.STORE.CATEGORY.DELETE_CATEGORY: {
            return {
                ...state,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}
