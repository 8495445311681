import React, { useState } from 'react'
import {
    TextField,
    Icon,
    Button,
    StepLabel,
    Step,
    Stepper,
    Grid,
    Fab,
    Typography,
    Avatar,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import swal from 'sweetalert'
import useAuth from 'app/hooks/useAuth'
import { ToastError, ToastSuccess } from 'app/utils/Toast'
import { isImage } from 'app/utils/Validators'

export default function RegistrationHS({ vendorId, vendorEmail }) {
    // const getSteps = () => {
    //     return ['Account info']
    // }

    // function getStepContent(stepIndex) {
    //     switch (stepIndex) {
    //         case 0:
    //             return (
    //                 <form noValidate autoComplete="off">
    //                     <Grid container spacing={3}>
    //                         <Grid item lg={6} md={6} sm={12} xs={12}>
    //                             <TextField value={userName} onChange={(e) => setUserName(e.target.value)} className={`${classes.root} w-full mt-5`}
 
    //                                 helperText={userNameError ? "User Name needs to be in alphabets or alphanumeric no special characters" : "Perfect!"}
    //                                 error={userNameError} />
    //                         </Grid>
    //                         <Grid item lg={6} md={6} sm={12} xs={12}>
    //                             <TextField value={email} onChange={(e) => setEmail(e.target.value)} className={`${classes.root} w-full mt-5`}
    //                                 helperText={emailError ? "Email needs to be in valid format i.e jhon@example.com" : "Perfect!"}
    //                                 error={emailError}
    //                             />
    //                         </Grid>
    //                         <Grid item lg={6} md={6} sm={12} xs={12}>
    //                             <TextField value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className={`${classes.root} w-full mt-5`}
    //                                 helperText={phoneNumberError ? "Phone Number needs to be in valid format i.e 10-15 digits" : "Perfect!"}
    //                                 error={phoneNumberError}
    //                             />
    //                         </Grid>
    //                         <Grid item lg={6} md={6} sm={12} xs={12}>
    //                             {/* <Typography>choose avatar</Typography> */}
    //                             {!avatar && <div className="flex flex-wrap mb-6">
    //                                 <label htmlFor="upload-single-file">
    //                                     <Fab
    //                                         className="capitalize"
    //                                         color="primary"
    //                                         component="span"
    //                                         variant="extended"
    //                                     >
    //                                         <div className="flex items-center">
    //                                             <Icon className="pr-8">cloud_upload</Icon>
    //                                             <span>Avatar</span>
    //                                         </div>
    //                                     </Fab>
    //                                 </label>
    //                                 <input
    //                                     className="hidden"
    //                                     onChange={(e) => {
    //                                         if (e.target.files.length === 1) {
    //                                             setAvatar(e.target.files[0])
    //                                             let img = new Image();
    //                                             let file = e.target.files[0];
    //                                             img.src = URL.createObjectURL(file)
    //                                             img.addEventListener("load", () => {
    //                                                 if (img.width !== 120 || img.height !== 120 || !(/^image\/(png|jpe?g)$/).test(file.type)) {
    //                                                     alert("image must be a png/jpeg/jpg with dimensions 120X120")
    //                                                     setAvatar(null);
    //                                                 }
    //                                             })
    //                                         } else {
    //                                             alert("Only one image can be selected")
    //                                         }
    //                                     }}
    //                                     id="upload-single-file"
    //                                     type="file"
    //                                 />
    //                             </div>}
    //                             {avatar && <Avatar style={{ width: 120, height: 120, marginBottom: 20 }} src={URL.createObjectURL(avatar)} alt='user-avatar' />}
    //                         </Grid>

    //                         <FormControlLabel
    //                             control={<Checkbox checked={agreement} onChange={setAgreement(!agreement)} />}
    //                             label="I have read and agree to the terms of service."
    //                         />
    //                     </Grid>
    //                 </form>
    //             )
    //         default:
    //             return ''
    //     }
    // }

    // const [activeStep, setActiveStep] = useState(0)
    // const steps = getSteps()

    // const handleNext = () => {
    //     if (activeStep === 0) {
    //         if (!userNameError && !emailError && !phoneNumberError) {
    //             handleFormSubmit();
    //         } else {
    //             swal({
    //                 title: "Invalid information",
    //                 text: `Hmm the information seems to be invalid`,
    //                 icon: "warning",
    //                 // closeOnClickOutside:false,
    //                 // dangerMode: true,
    //             });
    //         }
    //     }
    //     // if (activeStep === 1) {
    //     //     if (!storeNameError && !storeAddressError && !storeDescriptionError && storeLogoImage && storeBannerImages.length >= 3 && agreement) {
    //     //         handleFormSubmit();
    //     //     } else {
    //     //         // if(!storeLogoImage) {
    //     //         //     swal({
    //     //         //         title: "Invalid information",
    //     //         //         text: `a store logo is required to present your store to customers`,
    //     //         //         icon: "warning",
    //     //         //         // closeOnClickOutside:false,
    //     //         //         // dangerMode: true,
    //     //         //     });
    //     //         // }

    //     //         // if(storeBannerImages.length < 3) {
    //     //         //     swal({
    //     //         //         title: "Invalid information",
    //     //         //         text: `at least 3 680X227 banner images 40KB each are required`,
    //     //         //         icon: "warning",
    //     //         //         // closeOnClickOutside:false,
    //     //         //         // dangerMode: true,
    //     //         //     });
    //     //         // }

    //     //         swal({
    //     //             title: "Invalid information",
    //     //             text: `Here are the steps you can follow
    //     //                    \n 1. Store Name (as guided)
    //     //                    \n 2. Store Address (as guided)
    //     //                    \n 3. Store Description (as guided)
    //     //                    \n 4. Store Logo (as guided)
    //     //                    \n 5. 3 Store Banners (as guided)
    //     //                    \n 6. Agree to Terms&Conditions/PrivacyPolicy by viewing it
    //     //             `,
    //     //             icon: "warning",
    //     //             // closeOnClickOutside:false,
    //     //             // dangerMode: true,
    //     //         });

    //     //     }
    //     // }
    // }

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1)
    // }

    // const handleReset = () => {
    //     setActiveStep(0)
    // }

    // const handleSubmit = () => {
    //     swal("Done!", "You are now a part of iibso community!", "success");
    // }

    // const handleChange = ({ target: { name, value } }) => {
    //     setState({
    //         ...state,
    //         [name]: value,
    //     })
    // }

    // return (
    //     <div>
    //         <Stepper activeStep={activeStep} alternativeLabel>
    //             {steps.map((label) => (
    //                 <Step key={label}>
    //                     <StepLabel>{label}</StepLabel>
    //                 </Step>
    //             ))}
    //         </Stepper>
    //         <div>
    //             {activeStep === steps.length ? (
    //                 <div>
    //                     <div className="flex flex-column items-center mb-4">
    //                         {/* <Icon>done</Icon> <span className="ml-2">Done</span> */}
    //                         <Typography>Voila! you have just registered with IIBSO</Typography>
    //                         <Avatar
    //                             alt="registration-complete"
    //                             src="/assets/images/auth/registration/done-profile-setup.gif"
    //                             style={{ width: 120, height: 120 }}
    //                         />
    //                     </div>
    //                     {/* <Button
    //                         variant="contained"
    //                         // color="secondary"
    //                         onClick={handleReset}
    //                         style={{backgroundColor: '#3F6AB3'}}
    //                         >
    //                         Back
    //                     </Button>
    //                     <Button
    //                         variant="contained"
    //                         className={'mx-10'}
    //                         style={{backgroundColor: '#3FC2BD'}}
    //                         onClick={handleSubmit}
    //                         >
    //                         Submit
    //                     </Button> */}
    //                 </div>
    //             ) : (
    //                 <div>
    //                     {getStepContent(activeStep)}
    //                     <div className="pt-6">
    //                         {activeStep !== 0 && <Button
    //                             variant="contained"
    //                             // color="secondary"
    //                             style={{ backgroundColor: '#3F6AB3', color: '#FFFFFF' }}
    //                             disabled={activeStep === 0}
    //                             onClick={handleBack}
    //                         >
    //                             Back
    //                         </Button>}
    //                         <Button
    //                             className="ml-4"
    //                             variant="contained"
    //                             // color="primary"
    //                             style={{ backgroundColor: '#3FC2BD', color: '#FFFFFF' }}
    //                             onClick={handleNext}
    //                         >
    //                             {activeStep === steps.length - 1
    //                                 ? 'Finish'
    //                                 : 'Next'}
    //                         </Button>
    //                     </div>
    //                 </div>
    //             )}
    //         </div>
    //     </div>
    // )

    const useStyles = value =>
  makeStyles(theme => ({
    root: {
      "& .Mui-error": {
        color: acquireValidationColor(value)
      },
      "& .MuiFormHelperText-root": {
        color: acquireValidationColor(value)
      }
    }
  }));

  const acquireValidationColor = message => {
    switch (message) {
      case "Incorrect entry":
        return "black";
      case "Please input":
        return "black";
      default:
        return "black";
    }
  };

    const [userName, setUserName] = React.useState('')
    const [email, setEmail] = React.useState(vendorEmail)
    const [password, setPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')
    const [phoneNumber, setPhoneNumber] = React.useState('')
    const [avatar, setAvatar] = React.useState(null)
    const [agreement, setAgreement] = React.useState(false)
    const { register } = useAuth()

    const userNameError = !new RegExp(/^[(a-z|A-Z)][a-z|A-Z|0-9]*$/gim).test(
        userName
    )
    const emailError = !new RegExp(
        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/gim
    ).test(email)
    const passwordError = !new RegExp(
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/gim
    ).test(password)
    const confirmPasswordError = !new RegExp(
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/gim
    ).test(confirmPassword) || confirmPassword !== password
    const phoneNumberError = !new RegExp(
        /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
        ).test(phoneNumber)
        // /^[+]?([0-9]*[\.\s\-\(\)]|[0-9]+){3,24}$/gim
        // var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    // var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    const handleFormSubmit = () => {
        if (
            !userNameError &&
            !emailError &&
            !passwordError &&
            !phoneNumberError &&
            password === confirmPassword &&
            email === vendorEmail &&
            agreement === true
        ) {
            try {
                let vendor = {}

                vendor = {
                    vendorId,
                    userName,
                    email,
                    password,
                    phoneNumber,
                    avatar,
                    agreement,
                }
                register(vendor)
            } catch (e) {
                console.log(e)
            }
        } else {
            swal({
                title: 'Invalid information',
                text: `Here are the steps you can follow 
                                   \n 1. User Name (a mix of character and number)
                                   \n 2. Password (comprising of  1 lowercase alphabetical character, 1 uppercase alphabetical character, 1 numeric character, 1 special character & 8 characters in length )
                                   \n 3. Password and combine password should be same.
                                   \n 4. Valid US PhoneNumber ie. (xxx- xxxxx- xxx)
                                   \n 5. Agree to Terms&Conditions/PrivacyPolicy. 
                            `,
                icon: 'warning',
                // closeOnClickOutside:false,
                // dangerMode: true,
            })
        }
    }

    const classes = useStyles()();

    return (
        <form noValidate autoComplete="off">
            <Grid container spacing={3}>
                <Grid item lg={6} md={6} sm={12} xs={12}>

                    <TextField
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={`${classes.root} w-full mt-5`}
                        label="Email"
                        inputProps={{ maxLength: 320 }}
                        required
                        disabled
                        // helperText={
                        //     emailError
                        //         ? 'Email needs to be in valid format i.e jhon@example.com'
                        //         : 'Perfect!'
                        // }
                        error={emailError}
                        style={{ marginTop: 15 }}
                    />

                    <TextField
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        className={`${classes.root} w-full mt-5`}
                        label="User Name"
                        inputProps={{ maxLength: 30 }}
                        required
                        helperText={
                            userNameError
                                ? 'User Name needs to be in alphabets or alphanumeric no special characters'
                                : 'Perfect!'
                        }
                        error={userNameError}
                    />

                    <TextField
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className={`${classes.root} w-full mt-5`}

                        label="Phone"
                        required
                        helperText={
                            phoneNumberError
                                ? 'Phone Number needs to be a valid phone number'
                                : 'Perfect!'
                        }
                        error={phoneNumberError}
                    />



                    <TextField
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={`${classes.root} w-full mt-5`}

                        label="Password"
                        inputProps={{ minLength: 8 }}
                        required
                        type={'password'}
                        helperText={
                            passwordError
                                ? 'password needs to be in valid format i.e 1 lowercase alphabetical character, 1 uppercase alphabetical character, 1 numeric character, 1 special character & 8 characters in length'
                                : 'Perfect!'
                        }
                        error={passwordError}
                    />


                    <TextField
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className={`${classes.root} w-full mt-5`}

                        label="Confirm Password"
                        inputProps={{ minLength: 8 }}
                        required
                        type={'password'}
                        helperText={
                            confirmPasswordError
                                ? 'password needs to be in valid format i.e 1 lowercase alphabetical character, 1 uppercase alphabetical character, 1 numeric character, 1 special character & 8 characters in length'
                                : 'Perfect!'
                        }
                        error={confirmPasswordError}
                    />








                    {/* <Typography>choose avatar</Typography> */}
                    {/* {!avatar && (
                        <div className="flex flex-wrap mt-6 mb-6">
                            <label htmlFor="upload-single-file">
                                <Fab
                                    className="capitalize"
                                    color="primary"
                                    component="span"
                                    variant="extended"
                                >
                                    <div className="flex items-center">
                                        <Icon className="pr-8">
                                            cloud_upload
                                        </Icon>
                                        <span>Avatar</span>
                                    </div>
                                </Fab>
                            </label>
                            <input
                                className="hidden"
                                onChange={(e) => {
                                    if (e.target.files.length === 1) {
                                        let file = e.target.files[0]
                                        if (!isImage(file)) {
                                            ToastError(
                                                'image must be a png/jpeg/jpg with dimensions 120X120'
                                            )
                                        } else {
                                            setAvatar(e.target.files[0])
                                            let img = new Image()
                                            img.src = URL.createObjectURL(file)
                                            img.addEventListener('load', () => {
                                                if (
                                                    img.width !== 120 ||
                                                    img.height !== 120
                                                ) {
                                                    ToastError(
                                                        'image must be a with dimensions 120 X 120'
                                                    )
                                                    setAvatar(null)
                                                }
                                            })
                                        }
                                    } else {
                                        ToastError(
                                            'Only one image can be selected'
                                        )
                                    }
                                }}
                                id="upload-single-file"
                                type="file"
                            />
                        </div>
                    )}
                    {avatar && (
                        <Avatar
                            style={{
                                width: 120,
                                height: 120,
                                marginBottom: 20,
                            }}
                            src={URL.createObjectURL(avatar)}
                            alt="user-avatar"
                        />
                    )} */}
                </Grid>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={agreement}
                            onChange={() => setAgreement(!agreement)}
                        />
                    }
                    label="I have read and agree to the terms of service."
                />

                <Button
                    className=" mt-4"
                    variant="contained"
                    // color="primary"
                    style={{ backgroundColor: '#3FC2BD', color: '#FFFFFF' }}
                    onClick={handleFormSubmit}
                >
                    Submit
                </Button>
            </Grid>
        </form>
    )
}
