import React from 'react'
import { authRoles } from '../../utils/services/auth/authRoles'

const dashboardRoutes = [
    {
        path: '/vendor/dashboard', 
        component: React.lazy(() => import('../cards/DashboardWelcomeCard')),
        auth: authRoles.sa,
    },
    {
        path: '/dashboard/analytics2',
        component: React.lazy(() => import('./Analytics2')),
        auth: authRoles.sa,
    },
    {
        path: '/dashboard/inventory-management',
        component: React.lazy(() => import('./InventoryManagement')),
        auth: authRoles.sa,
    }
]

export default dashboardRoutes
