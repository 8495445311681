import NotFound from './NotFound'
import ForgotPassword from './verify/ForgotPassword'
import JwtRegister from './register/JwtRegister'
import JwtLogin from './login/JwtLogin'
import EmailVerification from './verify/Email'
import OtpVerification from './verify/Otp'
import Login from "./NewLoginFlow/Login.jsx"
const sessionRoutes = [
    {
        path: '/session/auth/login',
        component: Login,
    },
    {
        path: '/session/auth/verify/email',
        component: EmailVerification,
    },
    {
        path: '/session/auth/verify/otp',
        component: OtpVerification,
    },
    {
        path: '/session/auth/signup',
        component: JwtRegister,
    },
    {
        path: '/session/auth/signin',
        component: JwtLogin,
    },
    {
        path: '/session/forgot-password',
        component: ForgotPassword,
    },
    {
        path: '/session/404',
        component: NotFound,
    },
]

export default sessionRoutes
