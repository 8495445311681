import http from 'app/config/http'
import { ACTIONS } from 'app/constants/actions'
import { ToastError } from 'app/utils/Toast'

export async function getVendorStoreDetails(vendorId, dispatch) {
    try {
        let response = await http(`vendors/${vendorId}/stores`)

        if (response?.success) {
            // console.log(response)

            dispatch({
                type: ACTIONS.VENDOR.PROFILE.GET_STORE_DETAILS,
                payload: response.data[0],
            })
        } else throw new Error(response.message)
    } catch (error) {
        ToastError(`failed to fetch vendor store!`)
        console.log(`failed to fetch vendor store: ${error}`)
    }
}
