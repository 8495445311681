import { ACTIONS } from 'app/constants/actions'

const initialState = {
    conversations: [],
    chat: [],
    refunds: [],
}

export default function storeSupportReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.STORE.SUPPORT.GET_CONVERSATIONS: {
            return {
                ...state,
                conversations: [...action.payload],
            }
        }
        case ACTIONS.STORE.SUPPORT.GET_CONVERSATION_CHAT: {
            return {
                ...state,
                chat: [...action.payload],
            }
        }
        case ACTIONS.STORE.SUPPORT.GET_REFUNDS: {
            return {
                ...state,
                refunds: [...action.payload],
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}
