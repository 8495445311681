import React from 'react'
import { Redirect } from 'react-router-dom'

import dashboardRoutes from '../views/dashboard/DashboardRoutes'
import vendorRoutes from '../views/vendor/VendorRoutes'

// import utilitiesRoutes from '../views/utilities/UtilitiesRoutes'
// import chartsRoute from '../views/charts/ChartsRoute'
// import dragAndDropRoute from '../views/Drag&Drop/DragAndDropRoute'
import formsRoutes from '../views/forms/FormsRoutes'
// import mapRoutes from '../views/map/MapRoutes'
import materialRoutes from '../views/material-kit/MaterialRoutes'



const redirectRoute = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/vendor/dashboard" />,
    },
]

const errorRoute = [
    {
        path: '/session/404',
        exact: true,
        component: () => <Redirect to="/session/404" />,
    },
]

const routes = [
    ...redirectRoute,
    ...errorRoute,
    ...dashboardRoutes,
    ...vendorRoutes,
    ...materialRoutes,
    // ...utilitiesRoutes,
    // ...chartsRoute,
    // ...dragAndDropRoute,
    ...formsRoutes,
    // ...mapRoutes,
]

export default routes
