import { combineReducers } from 'redux'
import ScrumBoardReducer from './ScrumBoardReducer'
import NotificationReducer from './NotificationReducer'
import EcommerceReducer from './EcommerceReducer'
import NavigationReducer from './NavigationReducer'

import customerReducer from './customer/customerReducer'
import storeProfileReducer from './store/profileReducer'
import categoryReducer from './store/categoryReducer'
import subCategoryReducer from './store/subCategoryReducer'
import productReducer from './store/productReducer'
import storeSupportReducer from './store/supportReducer'
import vendorReducer from './vendor/vendorReducer'

const storeReducers = {
    vendorReducer,
    storeProfileReducer,
    categoryReducer,
    subCategoryReducer,
    productReducer,
    storeSupportReducer,
}

const RootReducer = combineReducers({
    // notifications: NotificationReducer,
    // navigations: NavigationReducer,
    // scrumboard: ScrumBoardReducer,
    // ecommerce: EcommerceReducer,
    // customer: customerReducer,
    ...storeReducers,
})

export default RootReducer
