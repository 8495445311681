const LOCAL_DEV_PORT = 9000

// const isDevelopmentEnv = true;
// process.env.NODE_ENV === 'development';
// https://q8rpm.herokuapp.com/api/v1

// ? `http://localhost:${LOCAL_DEV_PORT}/iibso.com/api/v1`
export const BASE_URL = `${
    process.env.NODE_ENV === 'development'
        ? `http://ec2-54-152-228-58.compute-1.amazonaws.com:9000/api/v1`
        : `http://ec2-54-152-228-58.compute-1.amazonaws.com:9000/api/v1`
        // : `https://iibso-staging.herokuapp.com/api/v1`
}`
