const capitalize = (string) => {
    return string && string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
const isEmpty = (str) => {
    if (str != null && str.replace(/\s/g, '').length) {
        return false;
    }
    return true;
}

const isValidEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};


const formatDate = (date) => {
    try {
        const today = new Date(date)
        const yyyy = today.getFullYear()
        let mm = today.getMonth() + 1 // Months start at 0!
        let dd = today.getDate()

        if (dd < 10) dd = '0' + dd
        if (mm < 10) mm = '0' + mm

        const updatedDate = dd + '/' + mm + '/' + yyyy
        return updatedDate
    } catch (error) {
        return ''
    }
}

export { capitalize, isEmpty, isValidEmail, formatDate }