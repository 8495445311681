import { ACTIONS } from 'app/constants/actions'

const initialState = {
    storeInfo: {},
    vendorInfo: {},
    vendorStripeAccountDetails:{}
}

export default function vendorReducer(state = initialState, action) {
    switch (action.type) {
        
        case ACTIONS.VENDOR.PROFILE.GET_STORE_DETAILS: { 
            return {
                ...state,
                storeInfo: action.payload
            }
        }
        case ACTIONS.VENDOR.PROFILE.GET_PROFILE_DETAILS: {
            return {
                ...state,
                vendorInfo: {...action.payload[0]}
            }
        }
        case ACTIONS.VENDOR.PROFILE.GET_STRIPE_ACCOUNT_DETAILS: {
            return {
                ...state,
                vendorStripeAccountDetails: {...action.payload}
            }
        }
        case ACTIONS.VENDOR.PROFILE.UPDATE_PROFILE: {
            return {
                ...state,
                vendorInfo: action.payload
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}
