import http from 'app/config/http'
import { ACTIONS } from 'app/constants/actions'
import { ToastError } from 'app/utils/Toast'

export async function getVendorProfileDetails(vendorId, dispatch) {
    try {
        let response = await http(`vendors/${vendorId}/profile`)

        console.log(response)

        if (response?.success) {
            localStorage.setItem("info", JSON.stringify(response.data[0]))
            dispatch({
                type: ACTIONS.VENDOR.PROFILE.GET_PROFILE_DETAILS,
                payload: response.data,
            })
        } else throw new Error(response.message)
    } catch (error) {
        ToastError(`failed to fetch vendor profile details!`)
        console.log(`failed to fetch vendor store profile details: ${error}`)
    }
}

export async function getVendorAccountDetailsStatus(vendorId, dispatch) {
    try {
        let response = await http(`vendors/${vendorId}/accounts/status`)

        // console.log(response)

        if (response?.success) {
            dispatch({
                type: ACTIONS.VENDOR.PROFILE.GET_ACCOUNT_DETAILS_STATUS,
                payload: response.data,
            })
        } else throw new Error(response.message)
    } catch (error) {
        ToastError(`failed to fetch vendor account details status!`)
        console.log(`failed to fetch vendor account details status: ${error}`)
    }
}

export async function getVendorStripeAccount(vendorId, dispatch) {
    try {
        let response = await http(`vendors/${vendorId}/accounts/stripe`)

        if (response?.success) {
            dispatch({
                type: ACTIONS.VENDOR.PROFILE.GET_STRIPE_ACCOUNT_DETAILS,
                payload: response.data,
            })
        } else throw new Error(response.message)
    } catch (error) {
        ToastError(`failed to fetch vendor stripe account details!`)
        console.log(`failed to fetch vendor stripe account details: ${error}`)
    }
}
