import React, { useState } from 'react'
import {
    Card,
    // Checkbox,
    // FormControlLabel,
    Grid,
    Button,
    CircularProgress,
} from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
// import { Link } from 'react-router-dom'
import useAuth from 'app/hooks/useAuth'
// import history from 'app/config/history.js'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    cardHolder: {
        background: '#1A2038',
    },
    card: {
        maxWidth: 800,
        borderRadius: 12,
        margin: '1rem',
    },
}))

export default function OtpVerification() {
    const [state, setState] = useState({})
    const [loading, setLoading] = useState(false)
    const classes = useStyles()
    const { otpVerification } = useAuth()
    const route = useLocation()

    const handleChange = ({ target: { name, value } }) => {
        setState({
            ...state,
            [name]: parseInt(value),
        })
    }

    const handleFormSubmit = async (event) => {
        try {
            setLoading(true)
            await otpVerification(route.state.email, state.otp)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }

    let { otp } = state

    return (
        <div
            className={clsx(
                'flex justify-center items-center  min-h-full-screen',
                classes.cardHolder
            )}
        >
            <Card className={classes.card}>
                <Grid container>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                        <div className="p-8 flex justify-center bg-light-gray items-center h-full">
                            <img
                                className="w-full"
                                src="/assets/images/auth/verification/otp-validation.jpg"
                                alt=""
                            />
                        </div>
                    </Grid>
                    <Grid item lg={7} md={7} sm={7} xs={12}>
                        <div className="p-8 h-full">
                            <h4 className="mb-4" style={{ color: 'gray' }}>
                                Verify OTP
                            </h4>
                            <ValidatorForm onSubmit={handleFormSubmit}>
                                <TextValidator
                                    className="mb-6 w-full"
                                    variant="outlined"
                                    size="small"
                                    label="Otp"
                                    onChange={handleChange}
                                    type="number"
                                    name="otp"
                                    value={otp || 'xxxxxx'}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                />
                                <Button
                                    className="capitalize"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={loading}
                                >
                                    Verify Otp
                                </Button>
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )}
                            </ValidatorForm>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </div>
    )
}
