import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Grid,
    IconButton,
    Icon,
    Radio,
    RadioGroup,
    FormControlLabel,
    Checkbox,
    Fab,
} from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import useAuth from 'app/hooks/useAuth'
import { getVendorStoreDetails } from 'app/redux/actions/vendor/profile/store'
import { BASE_URL } from 'app/constants/api'
import { ToastError, ToastSuccess } from 'app/utils/Toast'
import { isImage } from 'app/utils/Validators'
import { capitalize } from 'app/utils/Others'
import { useHistory } from 'react-router-dom'
import { resizeFile } from 'app/utils'
import { MatxLoading } from 'app/components'
import swal from 'sweetalert'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    indicator: {
        backgroundColor: '#3FC2BD',
    },
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {/* <Typography>{children}</Typography> */}
                    {children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

function fromYMD(ymd) {
    var t = ymd.split(/[- :]/) //split into components
    return new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0)
}

export default function StoreProfileTabs(props) {
    const [state, setState] = React.useState({
        name: '',
        address: '',
        description: '',
        logoImage: null,
        bannerImages: [],
    })
    const [isLoading, setIsLoading] = React.useState(false)
    const [value, setValue] = React.useState(0)
    const [nameFieldActive, setNameFieldActive] = React.useState(false)
    const [addressFieldActive, setAddressFieldActive] = React.useState(false)
    const [descriptionFieldActive, setDescriptionFieldActive] = React.useState(
        false
    )

    const { user } = useAuth()
    const dispatch = useDispatch()
    const storeInfo = useSelector((state) => state.vendorReducer.storeInfo)
    const history = useHistory()

    const handleTabChange = (event, newValue) => {
        setValue(newValue)
    }

    React.useEffect(() => {
        ValidatorForm.addValidationRule('isNameCorrect', (value) => {
            console.log(value)

            if (value !== state.name) {
                return false
            }
            return true
        })
        return () => ValidatorForm.removeValidationRule('isNameCorrect')
    }, [state.name])

    const handleSubmit = (event) => {
        // console.log("submitted");
        // console.log(event);
    }

    const handleChange = (event) => {
        event.persist()
        setState({
            ...state,
            [event.target.name]: event.target.value,
        })
    }

    const renderEditActions = (handleCancel, handleSave, field) => {
        return (
            <div>
                <Button
                    onClick={() => {
                        setState({
                            ...state,
                            [field]: '',
                        })
                        handleCancel()
                    }}
                    color="primary"
                    variant="outlined"
                    type="submit"
                >
                    <Icon style={{ fontSize: 10 }}>cancel</Icon>
                    <span className="pl-2 capitalize" style={{ fontSize: 10 }}>
                        cancel
                    </span>
                </Button>
                {state[field] && (
                    <Button
                        onClick={handleSave}
                        color="primary"
                        variant="outlined"
                        type="submit"
                        style={{ marginLeft: 8 }}
                    >
                        <Icon style={{ fontSize: 10 }}>savealt</Icon>
                        <span
                            className="pl-2 capitalize"
                            style={{ fontSize: 10 }}
                        >
                            save
                        </span>
                    </Button>
                )}
            </div>
        )
    }

    const renderEditButton = (handleEdit) => {
        return (
            <Button
                onClick={handleEdit}
                color="primary"
                variant="outlined"
                type="submit"
            >
                <Icon style={{ fontSize: 10 }}>edit</Icon>
                <span className="pl-2 capitalize" style={{ fontSize: 10 }}>
                    Edit
                </span>
            </Button>
        )
    }

    const saveField = async (fieldName, setFieldActive) => {
        try {
            let response = await fetch(
                `${BASE_URL}/stores/${storeInfo?.id}/update-fields`,
                {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        field: fieldName,
                        value: state[fieldName],
                    }),
                }
            )
            response = await response.json()
            if (response?.success) {
                getVendorStoreDetails(user.id, dispatch)
                setFieldActive(false)
                ToastSuccess(response.message)
            }
        } catch (error) { }
    }

    async function handleChooseNewBannerImage(e) {
        let files = e.target.files
        if (files.length === 1) {
            setIsLoading(true)
            if (isImage(files[0])) {
                const resizedFile = await resizeFile(files[0], 100, 100)
                let form = new FormData()
                form.append('storeId', storeInfo?.id)
                form.append('vendorId', user.id)
                form.append('image', resizedFile)
                let response = await fetch(
                    `${BASE_URL}/stores/${storeInfo?.id}/images/banner/add-new`,
                    { method: 'POST', body: form }
                )
                response = await response.json()
                if (response?.success) {
                    getVendorStoreDetails(user.id, dispatch)
                    setIsLoading(false)
                    ToastSuccess(response.message)
                } else {
                    setIsLoading(false)
                    ToastError(response.message)
                }
            } else {
                ToastError(
                    'image must be a png/jpeg/jpg with dimensions 373X461 pixels'
                )
            }
        } else {
            ToastError('Only one image can be selected')
        }
    }

    async function removeBannerImage(imageId, imageUrl) {
        const willDelete = await swal({
            title: 'Delete Banner Image',
            text: `Are you sure you want to delete ?`,
            icon: imageUrl,
            buttons: ['Cancel', 'Delete'],
            dangerMode: true,
        })

        if (willDelete) {
            setIsLoading(true)
            console.log(imageUrl);
            let response = await fetch(
                `${BASE_URL}/stores/${storeInfo?.id}/images/banners/${imageId}/delete`,
                { method: 'DELETE', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ imageUrl }) }
            )
            response = await response.json()
            if (response?.success) {
                getVendorStoreDetails(user.id, dispatch)
                setIsLoading(false)
                ToastSuccess(response.message)
            } else {
                setIsLoading(false)
                ToastError(response.message)
            }
        }
    }

    async function handleChooseStoreLogo(e) {
        let file = e.target.files
        if (isImage(file[0])) {
            const resizedFile = await resizeFile(file[0], 373, 461)
            setState({
                ...state,
                logoImage: resizedFile,
            })
            let form = new FormData()
            form.append('type', 'logo')
            form.append(
                'oldImageUrl',
                storeInfo?.logoImageUrl
            )
            form.append('image', resizedFile)
            let response = await fetch(
                `${BASE_URL}/stores/${storeInfo.id}/update-image`,
                { method: 'PUT', body: form }
            )
            response = await response.json()
            if (response?.success) {
                getVendorStoreDetails(user.id, dispatch)
                ToastSuccess(response.message)
            }
        } else {
            ToastError(
                'please select an actual image'
            )
        }
    }

    async function handleChooseStoreBanner(e, oldBannerImage) {
        // let files = e.target.files
        // if (files.length === 1) {
        //     if (isImage(files[0])) {
        //         let img = new Image()
        //         img.src = URL.createObjectURL(files[0])
        //         img.addEventListener('load', () => {
        //             if (img.width !== 680 || img.height !== 227) {
        //                 ToastError(
        //                     'image must be a png/jpeg/jpg with dimensions 680X227'
        //                 )
        //             } else {
        //                 // let myFile = new File([file.name],
        //                 //     img.src,
        //                 //     { type: file.type });
        //                 fetch(img.src)
        //                     .then((res) => res.blob())
        //                     .then(async (blob) => {
        //                         const myFile = new File(
        //                             [blob],
        //                             files[0].name,
        //                             blob
        //                         )
        //                         let form = new FormData()
        //                         form.append(
        //                             'oldBannerImageId',
        //                             oldBannerImage.id
        //                         )
        //                         form.append('oldImageUrl', oldBannerImage.url)
        //                         form.append('type', 'banner')
        //                         form.append('image', myFile)
        //                         let response = await fetch(
        //                             `${BASE_URL}/stores/${storeInfo.id}/update-image`,
        //                             { method: 'PUT', body: form }
        //                         )
        //                         response = await response.json()
        //                         if (response?.success) {
        //                             getVendorStoreDetails(user.id, dispatch)
        //                             ToastSuccess(response.message)
        //                         }
        //                     })
        //             }
        //         })
        //     } else {
        //         ToastError(
        //             'image must be a png/jpeg/jpg with dimensions 680X227'
        //         )
        //     }
        // } else {
        //     ToastError('Only one image can be selected')
        // }

        let file = e.target.files
        if (isImage(file[0])) {
            const resizedFile = await resizeFile(file[0], 373, 461)
            let form = new FormData()
            form.append(
                'oldBannerImageId',
                oldBannerImage.id
            )
            form.append('oldImageUrl', oldBannerImage.url)
            form.append('type', 'banner')
            form.append('image', resizedFile)
            let response = await fetch(
                `${BASE_URL}/stores/${storeInfo.id}/update-image`,
                { method: 'PUT', body: form }
            )
            response = await response.json()
            if (response?.success) {
                getVendorStoreDetails(user.id, dispatch)
                ToastSuccess(response.message)
            }
        } else {
            ToastError(
                'please select an actual image'
            )
        }
    }

    React.useEffect(() => {
        getVendorStoreDetails(user.id, dispatch)
    }, [])

    return (
        <>
        {isLoading && <MatxLoading />}
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    classes={{
                        indicator: useStyles().indicator,
                    }}
                    value={value}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                >
                    <Tab label="Items" {...a11yProps(0)} />
                    <Tab label="Reviews" {...a11yProps(1)} />
                    <Tab label="About" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                {props?.storeSubCategories?.length > 0 && (
                    <Typography
                        gutterBottom
                        style={{
                            fontSize: 20,
                            fontWeight: 'bolder',
                            marginLeft: 12,
                            marginBottom: 17,
                            color: 'gray',
                            // textAlign: 'center',
                        }}
                    >
                        Browse By Section
                    </Typography>
                )}
                <Grid container spacing={2}>
                    {props?.storeSubCategories?.length > 0 ? (
                        props?.storeSubCategories?.map((subCategory, i) => {
                            return (
                                <Grid key={i} item >
                                    <Card
                                        style={{
                                            // maxWidth: 320,
                                            width: '100%',
                                            boxShadow:
                                                '-1px 2px 7px -3px rgba(0,0,0,0.75)',
                                            margin: '10px 10px',
                                            padding: 10,
                                        }}
                                    >
                                        <div
                                            style={{
                                                borderRadius: 10,
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <CardMedia
                                                component="img"
                                                image={subCategory.image}
                                                alt="sub-category"
                                                style={{
                                                    borderRadius: 10,
                                                    width: 100,
                                                    justifyContent: 'center',
                                                }}
                                            />
                                        </div>
                                        <CardContent>
                                            <Typography
                                                gutterBottom
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {capitalize(subCategory.label)}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textPrimary"
                                            >
                                                {subCategory.description}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <div
                                                style={{
                                                    borderRadius: 10,
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    endIcon={
                                                        <Icon>visibility</Icon>
                                                    }
                                                    color={'primary'}
                                                    onClick={() => {
                                                        history.push(
                                                            `/vendor/store/catalog/${subCategory.id}/products`,
                                                            {
                                                                categoryId:
                                                                    subCategory.categoryId,
                                                            }
                                                        )
                                                    }}
                                                    style={{
                                                        fontSize: 10,
                                                    }}
                                                >
                                                    View Products
                                                </Button>
                                            </div>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )
                        })
                    ) : (
                        <Typography variant="h6">
                            There are'nt any subcategories yet
                        </Typography>
                    )}

                    {/* <Grid item md={6}>
                        <Card style={{ marginBottom: 15 }}>
                            <CardMedia
                                component="img"
                                height="140"
                                image="https://image.shutterstock.com/image-photo/happy-beautiful-asian-shopaholic-woman-260nw-1278669220.jpg"
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" >
                                    Item 3
                                </Typography>
                                <Typography variant="body2" color='textPrimary'>
                                    Lizards are a widespread group of squamate reptiles, with over 6,000
                                    species, ranging across all continents except Antarctica
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small">Share</Button>
                                <Button size="small">Learn More</Button>
                            </CardActions>
                        </Card>

                        <Card style={{ marginBottom: 15 }}>
                            <CardMedia
                                component="img"
                                height="140"
                                image="https://image.shutterstock.com/image-photo/happy-beautiful-asian-shopaholic-woman-260nw-1278669220.jpg"
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" >
                                    Item 4
                                </Typography>
                                <Typography variant="body2" color='textPrimary'>
                                    Lizards are a widespread group of squamate reptiles, with over 6,000
                                    species, ranging across all continents except Antarctica
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small">Share</Button>
                                <Button size="small">Learn More</Button>
                            </CardActions>
                        </Card>
                    </Grid> */}
                </Grid>
            </TabPanel>

            <TabPanel value={value} index={1}>
                <Grid container spacing={2}>
                    {props?.storeReviews?.length > 0 ? (
                        props?.storeReviews?.map((review, i) => {
                            return (
                                <Grid
                                    key={i}
                                    item
                                    style={{ marginBottom: 15 }}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                >
                                    <Card>
                                        <CardHeader
                                            avatar={
                                                <Avatar
                                                    style={{
                                                        backgroundColor: 'red',
                                                    }}
                                                    aria-label="recipe"
                                                >
                                                    R
                                                </Avatar>
                                            }
                                            action={
                                                <IconButton aria-label="settings"></IconButton>
                                            }
                                            title={review.userName}
                                            subheader={fromYMD(
                                                review.createdAt
                                            ).toLocaleDateString()}
                                        />
                                        <CardMedia
                                            component="img"
                                            height="194"
                                            image={review.productImage}
                                            alt="product"
                                        />
                                        <CardContent>
                                            <Typography
                                                variant="body2"
                                                color="textPrimary"
                                            >
                                                {review.text}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })
                    ) : (
                        <Typography variant="h6">
                            There are'nt any reviews yet
                        </Typography>
                    )}

                    {/* <Grid item style={{ marginBottom: 15 }} lg={6} md={6} sm={12}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar style={{ backgroundColor: 'red' }} aria-label="recipe">
                                        R
                                    </Avatar>
                                }
                                action={
                                    <IconButton aria-label="settings">
                                    </IconButton>
                                }
                                title="Shrimp and Chorizo Paella"
                                subheader="September 14, 2016"
                            />
                            <CardMedia
                                component="img"
                                height="194"
                                image="https://images.pexels.com/photos/90946/pexels-photo-90946.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                                alt="Paella dish"
                            />
                            <CardContent>
                                <Typography variant="body2" color='textPrimary'>
                                    This impressive paella is a perfect party dish and a fun meal to cook
                                    together with your guests. Add 1 cup of frozen peas along with the mussels,
                                    if you like.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid> */}
                </Grid>
            </TabPanel>

            <TabPanel value={value} index={2}>
                <Card className="px-6 pt-2 pb-4">
                    {/* <ValidatorForm onSubmit={handleSubmit} onError={() => null}> */}
                    <ValidatorForm>
                        <Grid container spacing={6}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <h3 style={{ color: 'gray' }}>
                                    Personal Section
                                </h3>
                                <TextValidator
                                    className="w-full"
                                    label={storeInfo?.name}
                                    onChange={handleChange}
                                    type="text"
                                    name="name"
                                    value={state.name || ''}
                                    // placeholder={storeInfo?.name}
                                    disabled={!nameFieldActive}
                                    // validators={[
                                    //     // 'required',
                                    //     'minStringLength: 4',
                                    //     'maxStringLength: 9',
                                    // ]}
                                    errorMessages={['this field is required']}
                                />
                                <br />
                                {nameFieldActive &&
                                    renderEditActions(
                                        () => setNameFieldActive(false),
                                        () =>
                                            saveField(
                                                'name',
                                                setNameFieldActive
                                            ),
                                        'name'
                                    )}

                                {!nameFieldActive &&
                                    renderEditButton(() =>
                                        setNameFieldActive(true)
                                    )}

                                <TextValidator
                                    className="mb-4 w-full"
                                    label={storeInfo?.address}
                                    onChange={handleChange}
                                    type="textarea"
                                    name="address"
                                    value={state.address || ''}
                                    disabled={!addressFieldActive}
                                    // validators={['required']}
                                    errorMessages={[
                                        'this field is required',
                                        'address is too large',
                                    ]}
                                />
                                {addressFieldActive &&
                                    renderEditActions(
                                        () => setAddressFieldActive(false),
                                        () =>
                                            saveField(
                                                'address',
                                                setAddressFieldActive(false)
                                            ),
                                        'address'
                                    )}

                                {!addressFieldActive &&
                                    renderEditButton(() =>
                                        setAddressFieldActive(true)
                                    )}

                                <TextValidator
                                    className="mb-4 w-full"
                                    label={storeInfo?.description}
                                    onChange={handleChange}
                                    type="textarea"
                                    name="description"
                                    value={state.description || ''}
                                    disabled={!descriptionFieldActive}
                                    // validators={['required', 'isEmail']}
                                    errorMessages={[
                                        'this field is required',
                                        'Decription is too large',
                                    ]}
                                />
                                {descriptionFieldActive &&
                                    renderEditActions(
                                        () => setDescriptionFieldActive(false),
                                        () =>
                                            saveField(
                                                'description',
                                                setDescriptionFieldActive
                                            ),
                                        'description'
                                    )}

                                {!descriptionFieldActive &&
                                    renderEditButton(() =>
                                        setDescriptionFieldActive(true)
                                    )}
                                <br />
                                <br />
                                <h3 style={{ color: 'gray', marginTop: 10 }}>
                                    Gallery Section
                                </h3>
                                <br />
                                <h5
                                    style={{
                                        color: 'gray',
                                        marginTop: 10,
                                        marginBottom: 20,
                                        fontSize: 20,
                                    }}
                                >
                                    Logo
                                </h5>
                                <hr
                                    style={{
                                        color: 'gray',
                                        marginBottom: 30,
                                        opacity: 0.7,
                                    }}
                                />

                                <div
                                    style={{
                                        // marginTop: 20,
                                        width: 250,
                                        padding: 30,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        boxShadow: `-1px 2px 7px -3px rgba(0,0,0,0.75)`,
                                    }}
                                >
                                    <img
                                        src={
                                            (state.logoImage &&
                                                URL.createObjectURL(
                                                    state.logoImage
                                                )) ||
                                            storeInfo?.logoImageUrl
                                        }
                                    />
                                    <br />
                                    <input
                                        placeholder="choose store image"
                                        // className="hidden"
                                        onChange={handleChooseStoreLogo}
                                        multiple={false}
                                        id="upload-single-file"
                                        type="file"
                                    />
                                </div>
                                <br />
                                <br />
                                <h5
                                    style={{
                                        color: 'gray',
                                        marginTop: 10,
                                        marginBottom: 20,
                                        fontSize: 20,
                                    }}
                                >
                                    Banner Images
                                </h5>
                                <hr
                                    style={{
                                        color: 'gray',
                                        marginBottom: 30,
                                        opacity: 0.7,
                                    }}
                                />
                                {storeInfo?.bannerImages?.map((img, i) => {
                                    return (
                                        <div
                                            style={{
                                                marginTop: 20,
                                                padding: 30,
                                                boxShadow: `-1px 2px 7px -3px rgba(0,0,0,0.75)`
                                            }}
                                        >
                                            <div style={{ position: 'relative' }}>
                                                <span
                                                    style={{
                                                        position: 'absolute',
                                                        width: "100%",
                                                        padding: 4,
                                                        backgroundColor:
                                                            'rgb(0,0,0,0.5)',
                                                        zIndex: 10,
                                                        top: 0,
                                                        left: 0,
                                                    }}
                                                >
                                                    <Icon
                                                        onClick={() =>
                                                            removeBannerImage(img.id, img.url)
                                                        }
                                                        style={{
                                                            color: 'red',
                                                            right: 5,
                                                        }}
                                                    >
                                                        delete
                                                    </Icon>
                                                </span>
                                                <img src={img.url} width={"100%"} height={"100%"} />
                                            </div>
                                            <br />
                                            <br />
                                            <input
                                                placeholder="choose store banner image"
                                                // className="hidden"
                                                onChange={(e) =>
                                                    handleChooseStoreBanner(
                                                        e,
                                                        img
                                                    )
                                                }
                                                multiple={false}
                                                id="upload-single-file"
                                                type="file"
                                            />
                                            {/* <Button color="primary" variant="contained" type="submit">
                                           <Icon>update</Icon>
                                           <span className="pl-2 capitalize">edit</span>
                                       </Button> */}
                                            <br />
                                            <br />
                                        </div>
                                    )
                                })}


                                {storeInfo?.bannerImages?.length < 4 && (
                                    <div className="flex flex-row flex-wrap mb-6">
                                        <label htmlFor="upload-multiple-file">
                                            <Fab
                                                className="capitalize"
                                                color="primary"
                                                component="span"
                                                variant="extended"
                                            >
                                                <div className="flex items-center">
                                                    <Icon className="pr-8">
                                                        cloud_upload
                                                    </Icon>
                                                    <span>
                                                        {/* {state.productImages?.length >= 3
                                                ? 'Reselect Product Images'
                                                : 'Product Images'} */}
                                                        {'Add New Banner Image'}
                                                    </span>
                                                </div>
                                            </Fab>
                                        </label>
                                        <input
                                            className="hidden"
                                            onChange={handleChooseNewBannerImage}
                                            // multiple={true}
                                            id="upload-multiple-file"
                                            type="file"
                                        />
                                        <Typography
                                            variant="body2"
                                            style={{
                                                margin: '10px 10px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {' '}
                                            ( min 1 and max 4 images can be added ){' '}
                                        </Typography>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </Card>
            </TabPanel>
        </Box>
        </>
    )
}
