import { ACTIONS } from 'app/constants/actions'

const initialState = {
    products: [],
    selectedProductUpdatedDetails: {},
}

export default function productReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.STORE.PRODUCT.GET_PRODUCTS: {
            return {
                ...state,
                products: action.payload,
            }
        }
        case ACTIONS.STORE.PRODUCT.GET_PRODUCT_DETAILS: {
            return {
                ...state,
                selectedProductUpdatedDetails: action.payload,
            }
        }
        case ACTIONS.STORE.PRODUCT.ADD_NEW_PRODUCT: {
            return {
                ...state,
            }
        }
        case ACTIONS.STORE.PRODUCT.UPDATE_PRODUCT: {
            return {
                ...state,
            }
        }
        case ACTIONS.STORE.PRODUCT.DELETE_PRODUCT: {
            return {
                ...state,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}
