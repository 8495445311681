import React, { useState } from 'react'
import {
    Card,
    // Checkbox,
    // FormControlLabel,
    Grid,
    Button,
    CircularProgress,
} from '@material-ui/core'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'

import { makeStyles } from '@material-ui/core/styles'
import history from 'app/config/history.js'
import clsx from 'clsx'
import useAuth from 'app/hooks/useAuth'
import { BASE_URL } from 'app/constants/api'
import { ToastError, ToastSuccess } from 'app/utils/Toast'
import { toast } from 'react-toastify'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    cardHolder: {
        background: '#1A2038',
    },
    card: {
        maxWidth: 800,
        borderRadius: 12,
        margin: '1rem',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

export default function EmailVerification() {
    const [loading, setLoading] = useState(false)
    const [userInfo, setUserInfo] = useState({
        email: '',
    })
    const [isMailSended, setIsMailSended] = useState(false)
    const [message, setMessage] = useState('')
    const { user, emailVerification } = useAuth()

    const classes = useStyles()

    const handleChange = ({ target: { name, value } }) => {
        let temp = { ...userInfo }
        temp[name] = value
        setUserInfo(temp)
    }

    const handleFormSubmit = async (event) => {
        try {
            // await emailVerification(userInfo.email)
            // history.push('/session/auth/verify/otp', { email: userInfo.email })
                setLoading(true)
                let response = await fetch(
                    `${BASE_URL}/vendor/accounts/password/reset/request`,
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ email: userInfo.email }),
                    }
                )

                if (response) {
                    let responseJSON = await response.json()
                    if (responseJSON.success) {
                        setLoading(false)
                        ToastSuccess(
                            "Check your inbox for the next steps. If you don't receive an email, and it's not in your spam folder this could mean you signed up with a different address."
                        )
                        // history.push('/session/auth/login')
                        setIsMailSended(true)
                    }else{
                        toast(
                            responseJSON.message,
                            {
                                type:"error"
                            }
                            )
                    }
                }

        } catch (e) {
            setLoading(false)
            console.log(e)
            ToastError(e.message)
            setMessage(e.message)
        }
    }

    return (
        <div
            className={clsx(
                'flex justify-center items-center  min-h-full-screen',
                classes.cardHolder
            )}
        >
            <Card className={classes.card}>
                <Grid container>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                        <div className="p-8 flex justify-center items-center h-full">
                            {/* <img
                                className="w-200"
                                src="/assets/images/illustrations/dreamer.svg"
                                alt=""
                            /> */}
                            <img
                                className="w-full"
                                src="/assets/images/auth/verification/email-validation.jpg"
                                alt="app"
                            />
                        </div>
                    </Grid>
                    <Grid item lg={7} md={7} sm={7} xs={12}>
                        {!isMailSended ? (
                            <div className="p-8 h-full bg-light-gray relative">
                                <h4 className="mb-4" style={{ color: 'gray' }}>
                                    Reset Password.
                                </h4>
                                <ValidatorForm onSubmit={handleFormSubmit}>
                                    <TextValidator
                                        className="mb-6 w-full"
                                        variant="outlined"
                                        size="small"
                                        label="Email"
                                        onChange={handleChange}
                                        type="email"
                                        name="email"
                                        value={userInfo.email}
                                        validators={['required', 'isEmail']}
                                        errorMessages={[
                                            'this field is required',
                                            'email is not valid',
                                        ]}
                                    />

                                    {message && (
                                        <p className="text-error">{message}</p>
                                    )}

                                    <div className="flex flex-wrap items-center mb-4">
                                        <div className="relative">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                            <div className="relative">
                                                <Button
                                                    style={{
                                                        color: '#1976d2',
                                                        fontWeight: 'bolder',
                                                    }}
                                                    onClick={() => {
                                                        history.push(
                                                            '/session/auth/login'
                                                        )
                                                    }}
                                                >
                                                    Login
                                                </Button>

                                                <span>OR</span>

                                                <Button
                                                    style={{
                                                        color: '#1976d2',
                                                        fontWeight: 'bolder',
                                                    }}
                                                    onClick={() => {
                                                        history.push(
                                                            '/session/auth/verify/email'
                                                        )
                                                    }}
                                                >
                                                    SignUp
                                                </Button>
                                            </div>
                                            {loading && (
                                                <CircularProgress
                                                    size={24}
                                                    className={
                                                        classes.buttonProgress
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </ValidatorForm>
                            </div>
                        ) : (
                            <div className="p-8 h-full bg-light-gray relative">
                                <h4
                                    className="mb-4 mt-16"
                                    style={{ color: 'green' }}
                                >
                                    Please check you're email for futher
                                    instructions regarding reset your password.
                                </h4>
                                <div className="relative">
                                    <div className="relative">
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: 16,
                                            }}
                                        >
                                            go to
                                        </span>
                                        <Button
                                            style={{
                                                color: '#1976d2',
                                                fontWeight: 'bolder',
                                                textDecoration: 'underline',
                                            }}
                                            onClick={() => {
                                                history.push(
                                                    '/session/auth/login'
                                                )
                                            }}
                                        >
                                            Login
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Card>
        </div>
    )
}
