export const ACTIONS = {
    CUSTOMER: {
        GET_CUSTOMERS: 'GET_CUSTOMERS',
        GET_CUSTOMER_DETAILS: 'GET_CUSTOMER_DETAILS',
    },
    VENDOR: {
        PROFILE: {
            GET_STORE_DETAILS: 'GET_STORE_DETAILS',
            GET_PROFILE_DETAILS: 'GET_PROFILE_DETAILS',
            GET_ACCOUNT_DETAILS_STATUS: 'GET_ACCOUNT_DETAILS_STATUS',
            GET_STRIPE_ACCOUNT_DETAILS: 'GET_STRIPE_ACCOUNT_DETAILS',
            UPDATE_PROFILE: 'UPDATE_PROFILE',
        },
    },
    STORE: {
        PROFILE: {
            CREATE_PROFILE: 'CREATE_PROFILE',
            GET_PROFILE_DETAILS: 'GET_PROFILE_DETAILS',
            UPDATE_PROFILE: 'UPDATE_PROFILE',
        },
        CATEGORY: {
            GET_CATEGORIES: 'GET_CATEGORIES',
            GET_CATEGORY_DETAILS: 'GET_CATEGORY_DETAILS',
            ADD_NEW_CATEGORY: 'ADD_NEW_CATEGORY',
            UPDATE_CATEGORY: 'UPDATE_CATEGORY',
            DELETE_CATEGORY: 'DELETE_CATEGORY',
        },
        SUBCATEGORY: {
            GET_SUBCATEGORIES: 'GET_SUBCATEGORIES',
            GET_SUBCATEGORY_DETAILS: 'GET_SUBCATEGORY_DETAILS',
            ADD_NEW_SUBCATEGORY: 'ADD_NEW_SUBCATEGORY',
            UPDATE_SUBCATEGORY: 'UPDATE_SUBCATEGORY',
            DELETE_SUBCATEGORY: 'DELETE_SUBCATEGORY',
        },
        PRODUCT: {
            GET_PRODUCTS: 'GET_PRODUCTS',
            GET_PRODUCT_DETAILS: 'GET_PRODUCT_DETAILS',
            ADD_NEW_PRODUCT: 'ADD_NEW_PRODUCT',
            UPDATE_PRODUCT: 'UPDATE_PRODUCT',
            DELETE_PRODUCT: 'DELETE_PRODUCT',
        },
        FINANCES: {
            GET_INVOICES: 'GET_INVOICES',
            GET_ORDERS: 'GET_ORDERS',
        },
        SUPPORT: {
            GET_CONVERSATIONS: 'GET_CONVERSATIONS',
            GET_CONVERSATION_CHAT: 'GET_CONVERSATION_CHAT',
            GET_REFUNDS: 'GET_REFUNDS',
        },
    },
    NOTIFICATIONS: {
        GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
        DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
        CLEAR_ALL_NOTIFICATIONS: 'CLEAR_ALL_NOTIFICATIONS',
    },
}
