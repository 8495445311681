export let navigations = [
    {
        name: 'Dashboard',
        path: '/vendor/dashboard',
        icon: 'dashboard',
    },
    {
        label: 'Store',
        type: 'label',
    },
    {
        name: 'Store',
        // icon: 'add',
        // path: '/vendor/store/add-new',
        path: '/vendor/store/catalog/profile',
    },
    {
        name: 'Categories',
        // icon: 'add',
        path: '/vendor/store/catalog/subcategories',
    },
    // {
    //     name: 'Catalog',
    //     icon: 'shop',
    //     children: [
    //         // {
    //         //     name: 'profile',
    //         //     iconText: 'VP',
    //         //     path: '/vendor/store/catalog/profile',
    //         // },
    //         // {
    //         //     name: 'subcategories',
    //         //     iconText: 'SC',
    //         //     path: '/vendor/store/catalog/subcategories',
    //         // },
    //         // {
    //         //     name: 'products',
    //         //     iconText: 'P',
    //         //     path: '/vendor/store/catalog/products',
    //         // },
    //         // {
    //         //     name: 'customers',
    //         //     iconText: 'FP',
    //         //     path: '/vendor/store/catalog/customers',
    //         // },
    //     ],
    // },
    {
        label: 'Growth',
        type: 'label',
    },
    {
        name: 'Orders',
        // icon: 'add',
        path: '/vendor/growth/sales/orders',
        // children: [
        //     {
        //         name: 'Orders',
        //         // icon: 'add',
        //         path: '/vendor/growth/sales/orders',
        //     },
        //     {
        //         name: 'Billing',
        //         // iconText: 'FP',
        //         // path: '/vendor/growth/sales/billing',
        //         type: 'extLink',
        //     },
        // ],
    },
    {
        name: 'Billing',
        // path: '',
    },
    // {
    //     name: 'Finances',
    //     icon: 'money',
    //     path: '/vendor/growth/sales/finances',
    //     children: [
    //         {
    //             name: 'Billing',
    //             iconText: 'FP',
    //             path: '/vendor/growth/sales/billing',
    //         },
    //         {
    //             name: 'Payout',
    //             iconText: 'FP',
    //             path: '/vendor/growth/sales/payouts',
    //         }
    //     ],
    // },
    // {
    //     name: 'Sales',
    //     icon: 'money',
    //     path: '/session/forgot-password',
    //     children: [
    //         {
    //             name: 'orders',
    //             iconText: 'FP',
    //             path: '/vendor/growth/sales/orders',
    //         },
    //         // {
    //         //     name: 'invoices',
    //         //     iconText: 'SI',
    //         //     path: '/vendor/growth/sales/invoices',
    //         // }
    //     ],
    // },
    // {
    //     name: 'Stats',
    //     icon: 'trending_up',
    //     children: [
    //         {
    //             name: 'Daily',
    //             iconText: 'FP',
    //             path: '/session/forgot-password',
    //         },
    //         {
    //             name: 'Specific',
    //             iconText: 'SI',
    //             path: '/session/signin',
    //         }
    //     ],
    // },
    // {
    //     name: 'Support',
    //     icon: 'support',
    //     children: [
    //         {
    //             name: 'chats',
    //             iconText: 'SI',
    //             path: '/vendor/store/support/conversations',
    //         },
    //         {
    //             name: 'refunds',
    //             iconText: 'SI',
    //             path: '/vendor/store/support/refunds',
    //         }
    //     ],
    // },
    // {
    //     label: 'Pages',
    //     type: 'label',
    // },
    // {
    //     name: 'Session/Auth',
    //     icon: 'security',
    //     children: [
    //         {
    //             name: 'Sign in',
    //             iconText: 'SI',
    //             path: '/session/signin',
    //         },
    //         {
    //             name: 'Sign up',
    //             iconText: 'SU',
    //             path: '/session/signup',
    //         },
    //         {
    //             name: 'Forgot Password',
    //             iconText: 'FP',
    //             path: '/session/forgot-password',
    //         },
    //         {
    //             name: 'Error',
    //             iconText: '404',
    //             path: '/session/404',
    //         },
    //     ],
    // },

    // {
    //     label: 'Components',
    //     type: 'label',
    // },
    // {
    //     name: 'Components',
    //     icon: 'favorite',
    //     badge: { value: '30+', color: 'secondary' },
    //     children: [
    //         {
    //             name: 'Auto Complete',
    //             path: '/material/autocomplete',
    //             iconText: 'A',
    //         },
    //         {
    //             name: 'Buttons',
    //             path: '/material/buttons',
    //             iconText: 'B',
    //         },
    //         {
    //             name: 'Checkbox',
    //             path: '/material/checkbox',
    //             iconText: 'C',
    //         },
    //         {
    //             name: 'Dialog',
    //             path: '/material/dialog',
    //             iconText: 'D',
    //         },
    //         {
    //             name: 'Drag and Drop',
    //             iconText: 'D',
    //             path: '/others/drag-and-drop',
    //         },
    //         {
    //             name: 'Expansion Panel',
    //             path: '/material/expansion-panel',
    //             iconText: 'E',
    //         },
    //         {
    //             name: 'Form',
    //             path: '/material/form',
    //             iconText: 'F',
    //         },
    //         {
    //             name: 'Icons',
    //             path: '/material/icons',
    //             iconText: 'I',
    //         },
    //         {
    //             name: 'Menu',
    //             path: '/material/menu',
    //             iconText: 'M',
    //         },
    //         {
    //             name: 'Progress',
    //             path: '/material/progress',
    //             iconText: 'P',
    //         },
    //         {
    //             name: 'Radio',
    //             path: '/material/radio',
    //             iconText: 'R',
    //         },
    //         {
    //             name: 'Switch',
    //             path: '/material/switch',
    //             iconText: 'S',
    //         },
    //         {
    //             name: 'Slider',
    //             path: '/material/slider',
    //             iconText: 'S',
    //         },
    //         {
    //             name: 'Snackbar',
    //             path: '/material/snackbar',
    //             iconText: 'S',
    //         },
    //         {
    //             name: 'Table',
    //             path: '/material/table',
    //             iconText: 'T',
    //         },
    //     ],
    // },
    // {
    //     name: 'Utilities',
    //     icon: 'format_list_bulleted',
    //     children: [
    //         {
    //             name: 'Color',
    //             path: '/utilities/color',
    //             iconText: 'C',
    //             auth: authRoles.admin,
    //         },
    //         {
    //             name: 'Spacing',
    //             path: '/utilities/spacing',
    //             iconText: 'S',
    //             auth: authRoles.admin,
    //         },
    //         {
    //             name: 'Typography',
    //             path: '/utilities/typography',
    //             iconText: 'T',
    //         },
    //         {
    //             name: 'Display',
    //             path: '/utilities/display',
    //             iconText: 'D',
    //         },
    //         {
    //             name: 'Position',
    //             path: '/utilities/position',
    //             iconText: 'P',
    //         },
    //         {
    //             name: 'Shadow',
    //             path: '/utilities/shadow',
    //             iconText: 'S',
    //         },
    //     ],
    // },
    // {
    //     name: 'Charts',
    //     icon: 'trending_up',
    //     children: [
    //         {
    //             name: 'Echarts',
    //             path: '/charts/echarts',
    //             iconText: 'E',
    //         },
    //     ],
    // },
    // {
    //     name: 'Documentation',
    //     icon: 'launch',
    //     type: 'extLink',
    //     path: 'http://demos.ui-lib.com/matx-react-doc/',
    // },
]
