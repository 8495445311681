import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'app/config/axios'
// import bcrypt from 'bcryptjs'
import { MatxLoading } from 'app/components'
// import { createBrowserHistory } from 'history'
import http from 'app/config/http'
import { useHistory } from "react-router-dom"
import { getVendorAccountDetailsStatus } from 'app/redux/actions/vendor/profile/details'
import { ToastError, ToastSuccess } from 'app/utils/Toast'
import { toast } from 'react-toastify'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    restoreSession: false,
    user: null,
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'RESTORE': {
            const { restoreSession } = action.payload

            return {
                ...state,
                isInitialised: true,
                restoreSession,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return { tokenInfo: decodedToken, valid: decodedToken.exp > currentTime }
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('info')
        delete axios.defaults.headers.common.Authorization
    }
}

const setInfo = (info) => localStorage.setItem('info', JSON.stringify(info))

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    emailVerification: () => Promise.resolve(),
    otpVerification: () => Promise.resolve(),
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const history = useHistory();

    const emailVerification = async (email) => {
        try {
            const body = { data: { email }, whatToVerify: 'email' }

            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
            }

            let response = await http(`vendor/verify`, options)

            if (response?.success) {
                const { message } = response
                ToastSuccess(message)
            } else {
                ToastError(response.message)
                throw new Error(response.message)
            }
        } catch (error) {
            ToastError(error)
        }
    }

    const otpVerification = async (email, otp) => {
        try {
            const body = { data: { email, otp }, whatToVerify: 'otp' }

            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
            }

            let response = await http(`vendor/verify`, options)

            if (response?.success) {
                const { message, data } = response
                ToastSuccess(message)
                if (data.vendor.isFirstTime === 'TRUE') {
                    history.push('/session/auth/signup', {
                        vendorId: data.vendor.id,
                        email: data.vendor.email
                    })
                } else {

                    // getVendorAccountDetailsStatus(data.vendor.id, dispatch);

                    setSession(data.accessToken)

                    setInfo(data.vendor)

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user: data.vendor,
                        },
                    })

                    history.push('/vendor/dashboard')

                }
            } else {
                ToastError(response.message);
                throw new Error(response.message)
            }
        } catch (error) {
            ToastError(error)
        }
    }

    const login = async (email, password) => {
        try {
            // let hash = bcrypt.hashSync(password, 8)
            // const options = {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //         email,
            //         password: hash,
            //     }),
            // }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email, password
                }),
            }

            let response = await http(`vendor/login`, options)

            if (response?.success) {
                ToastSuccess(response.message)

                const { meta, vendor } = response.data
                setSession(meta.accessToken)
                setInfo(vendor)

                dispatch({
                    type: 'LOGIN',
                    payload: {
                        user: vendor,
                    },
                });

                history.push('/vendor/dashboard')
            } else {
                toast(`Sorry!, ${response.message}`, { type: "error" })
                // ToastError(response.message)
                throw new Error(response.message)
            }
        } catch (error) {
            toast(`Sorry!, no vendor found with this email and password`, { type: "error" })

            ToastError(error.message)
        }
    }

    const register = async (vendor) => {
        console.log(vendor, "VENDOR REGISTRATION DETAILS");
        // const response = await axios.post('/api/auth/register', {
        //     email,
        //     username,
        //     password,
        // })

        // const { accessToken, user } = response.data

        // setSession(accessToken)

        // dispatch({
        //     type: 'REGISTER',
        //     payload: {
        //         user,
        //     },
        // })

        try {
            // let hash = bcrypt.hashSync(password, 8)
            // const options = {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //         email,
            //         password: hash,
            //     }),
            // }

            let form = new FormData();

            if (vendor.avatar) form.append("avatar", vendor.avatar);
            delete vendor.avatar
            Object.entries(vendor).forEach((entry) => form.append(entry[0], entry[1]));

            const options = {
                method: 'POST',
                body: form,
            }

            let response = await http(`vendor/registration`, options)

            if (response?.success) {

                const { accessToken, vendor } = response.data

                // const accessToken =
                //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
                // const user = {
                //     id: 1,
                //     role: 'SA',
                //     name: 'Jason Alexander',
                //     username: 'jason_alexander',
                //     email: 'jason@ui-lib.com',
                //     avatar: '/assets/images/face-6.jpg',
                //     age: 25,
                // }

                setSession(accessToken)

                setInfo(vendor)

                dispatch({
                    type: 'REGISTER',
                    payload: {
                        user: vendor,
                    },
                })

                history.push('/vendor/dashboard')
            } else {
                ToastError(response.message)
                throw new Error(response.message)
            }
        } catch (error) {
            ToastError(error)
        }
    }

    const logout = async () => {
        const options = {
            method: "DELETE"
        }

        let response = await http(`sessions/${localStorage.getItem("accessToken")}/logout`, options)

        if (response?.success) {
            setSession(null)
            dispatch({ type: 'LOGOUT' })
        } else {
            return
        }
    }

    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
                const info = window.localStorage.getItem('info')

                // console.log(isValidToken(accessToken).valid)

                if (accessToken !== "null" && accessToken !== null && accessToken && isValidToken(accessToken).valid) {
                    setSession(accessToken)
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user: JSON.parse(info),
                        },
                    })

                        // let response = await fetch(`http://localhost:9000/iibso.com/api/v1/session/restore?accessToken=${accessToken}`)

                        ;

                    // const { success, message } = response

                    // if(success) {
                    //     alert(message);
                    //     dispatch({
                    //         type: 'RESTORE',
                    //         payload: {
                    //             restoreSession: true
                    //         },
                    //     })
                    //     history.push("/session/auth/verify/otp");
                    // }

                    // throw new Error(message)
                }
                else if (!accessToken || !isValidToken(accessToken).valid) {
                    await logout()
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
                else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                emailVerification,
                otpVerification,
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
