
const isImage = (file) => {
    if (file) {
        let t = file.type.split('/').pop().toLowerCase();
        if (t != "jpeg" && t != "jpg" && t != "png" && t != "bmp" && t != "gif") {
            return false;
        }
        return true;
    }
}

export { isImage }
