import { ACTIONS } from 'app/constants/actions'

const initialState = {
    store: {},
}

export default function storeProfileReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.STORE.PROFILE.GET_PROFILE_DETAILS: {
            return {
                ...state,
                store: [...action.payload],
            }
        }
        case ACTIONS.STORE.PROFILE.UPDATE_PROFILE: {
            return {
                ...state
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}
