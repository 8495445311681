import './config/fake-db'
import React from 'react'
import { Provider } from 'react-redux'
import { Router, Switch, Route, BrowserRouter } from 'react-router-dom'
import AppContext from './contexts/AppContext'
import history from './config/history.js'
import routes from './navigations/RootRoutes'
import Store from './redux/Store'
import { GlobalCss, MatxSuspense, MatxTheme, MatxLayout } from 'app/components'
import sessionRoutes from './views/sessions/SessionRoutes'
import AuthGuard from './utils/services/auth/AuthGuard'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import { alpha } from '@material-ui/core/styles'

const App = () => {
    return (
        <AppContext.Provider value={{ routes }}>
            <Provider store={Store}>
                <SettingsProvider>
                    <MatxTheme>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <GlobalCss />
                        <BrowserRouter basename={process.env.PUBLIC_URL}>
                            <Router history={history}>
                                <AuthProvider>
                                    <MatxSuspense>
                                        <Switch>
                                            {/* AUTHENTICATION PAGES (SIGNIN, SIGNUP ETC.) */}
                                            {sessionRoutes.map((item, i) => (
                                                <Route
                                                    key={i}
                                                    path={item.path}
                                                    exact
                                                    component={item.component}
                                                />
                                            ))}
                                            {/* AUTH PROTECTED DASHBOARD PAGES */}
                                            <AuthGuard>
                                                {/* RETURNS <Layout1/> component */}
                                                <MatxLayout />
                                            </AuthGuard>
                                            {/* AUTH PROTECTED DASHBOARD PAGES */}
                                        </Switch>
                                    </MatxSuspense>
                                </AuthProvider>
                            </Router>
                        </BrowserRouter>
                        </MuiPickersUtilsProvider>
                    </MatxTheme>
                </SettingsProvider>
            </Provider>
            <ToastContainer />
        </AppContext.Provider>
    )
}

// function onPickFile(e){
//     let form = new FormData();
//     form.append("image", e.target.files[0]);
//     form.append("senderDetails", {
//         name:"umar",
//         age:25,
//         role:"Senior. full stack developer"
//     });
//     fetch('http://localhost:9000/iibso.com/api/v1/test/POST/file-with-body',{
//         method:"POST",
//         body:form
//     })
// }

// const App = () => {
//     return (
//         <input type={"file"} onChange={onPickFile}/>
//     )
// }

export default App
