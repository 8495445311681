import React from 'react'
import {
    Icon,
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Button,
} from '@material-ui/core'
import swal from 'sweetalert'

const ProductCard = ({
    product,
    isDisabledButtons,
    handleOpenEditProductDialog,
    deleteProduct,
}) => {
    console.log(product, "PRRORROROROROWRROASD");
    return (
        <Card
            style={{
                maxWidth: 320,
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                margin: '10px 10px',
                padding: 10,
            }}
        >
            <div className='flex w-full justify-center'>
            <CardMedia
                component="img"
                // width={345}
                style={{ borderRadius: 10 , height:100, width:100 }}
                image={
                    product?.imageUrl
                        ? product?.imageUrl
                        : 'https://via.placeholder.com/680'
                }
                alt="product-image"
            />
            </div>
            {/* <hr style={{ color: 'gray', opacity: 0.5, marginTop: 5 }} /> */}
            <CardContent>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        style={{ fontSize: 14 }}
                    >
                        {product?.quantity && product?.quantity + ' x '}
                        {product?.title}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontSize: 12 }}
                    >
                        ${product?.price}
                    </Typography>
                </div>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ fontSize: 12 }}
                >
                    {product?.description}
                </Typography>
            </CardContent>
            {!isDisabledButtons && (
                <CardActions>
                    {/* <Button variant="contained" endIcon={<Icon>visibility</Icon>} color={"primary"}>
                                    View
                                </Button> */}
                    <Button
                        variant="outlined"
                        endIcon={<Icon style={{ fontSize: 10 }}>edit</Icon>}
                        color={'primary'}
                        onClick={handleOpenEditProductDialog}
                        style={{ fontSize: 10 }}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<Icon style={{ fontSize: 10 }}>delete</Icon>}
                        style={{
                            borderColor: 'red',
                            color: 'red',
                            fontSize: 10,
                        }}
                        onClick={async () => {
                            const willDelete = await swal({
                                title: 'Are you sure?',
                                text: `Are you sure that you want to delete ${product?.title} ?`,
                                icon:
                                    product?.imageUrls &&
                                    product?.imageUrls[0].url,
                                buttons: ['Cancel', 'Delete'],
                                dangerMode: true,
                            })

                            if (willDelete) {
                                deleteProduct(product?.id)
                            }
                        }}
                    >
                        Delete
                    </Button>
                </CardActions>
            )}
        </Card>
    )
}

export default ProductCard
