import { ACTIONS } from 'app/constants/actions'

const initialState = {
    subCategories: null,
    selectedSubCategoryDetails: {},
}

export default function subCategoryReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.STORE.SUBCATEGORY.GET_SUBCATEGORIES: {
            return {
                ...state,
                subCategories: action.payload,
            }
        }
        case ACTIONS.STORE.SUBCATEGORY.GET_SUBCATEGORY_DETAILS: {
            return {
                ...state,
                selectedSubCategoryDetails: action.payload,
            }
        }
        case ACTIONS.STORE.SUBCATEGORY.ADD_NEW_SUBCATEGORY: {
            return {
                ...state,
            }
        }
        case ACTIONS.STORE.SUBCATEGORY.UPDATE_SUBCATEGORY: {
            return {
                ...state,
            }
        }
        case ACTIONS.STORE.SUBCATEGORY.DELETE_SUBCATEGORY: {
            return {
                ...state,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}
