import { toast } from 'react-toastify';

const ToastError = (message) => {
    // toast(`Sorry!, ${message}`, {type:"error"});
    console.log("ERROR TOAST=====>",message)
}

const ToastSuccess = (message) => {
    console.log("SUCCESS TOAST=====>",message)
    // toast(`Congratulation! ${message}`, {type:"success"});
}


export { ToastError, ToastSuccess }