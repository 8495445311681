import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
    loading: {
        position: 'fixed',
        zIndex: 99999,
        top:0,
        left:0,
        right:0,
        bottom:0,
        margin: 'auto',
        height: '100vh',
        width: '100vw',
        backgroundColor:'rgba(0,0,0, 0.5)',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        '& img': {
            position: 'absolute',
            height: '25px',
            width: 'auto',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
        },
    },
}))

const Loading = (props) => {
    const classes = useStyles()

    return (
        <div className={classes.loading}>
            {/* <img src="/assets/images/logo-circle.svg" alt="" /> */}
            <CircularProgress />
        </div>
    )
}

export default Loading
